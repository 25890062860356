<template>
  <b-container>
    <b-row class="mt-5">
      <b-col md="6" class="mx-auto">
        <h2 class="mb-2 text-primary">Welcome to MTB Research</h2>
        <h5 class="mb-5 text-secondary">
          Complete the signup process to access the platform
        </h5>

        <b-form @submit="onSubmit" v-if="!this.$store.state.hasCompletedSignup">
          <b-form-group
            id="input-group-2"
            label="First Name*"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.firstName"
              placeholder="Enter First Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Last Name*"
            label-for="input-3"
          >
            <b-form-input
              id="input-3"
              v-model="form.lastName"
              placeholder="Enter Last Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Job Title*"
            label-for="input-4"
          >
            <b-form-input
              id="input-4"
              v-model="form.jobTitle"
              placeholder="Enter Job Title"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-5" label="Company*" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="form.company"
              placeholder="Enter Company"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label="I work for a/an:*"
            label-for="input-6"
          >
            <b-form-select
              id="input-6"
              v-model="form.companyType"
              :options="companyTypeOptions"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label="Country:*"
            label-for="input-8"
          >
            <Multiselect
              :searchable="true"
              v-model="form.country"
              :options="countryOptions"
              required
            />
          </b-form-group>

          <b-form-group
            id="input-group-8"
            label="How did you find us:*"
            label-for="input-7"
          >
            <b-form-select
              id="input-7"
              v-model="form.foundUs"
              :options="foundUsOption"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-9"
            label="Areas of interest:*"
            label-for="input-8"
          >
            <Multiselect
              mode="tags"
              :searchable="true"
              :createTag="true"
              v-model="form.interests"
              :options="interestsOptions"
              required
            />
          </b-form-group>

          <b-form-group
            id="input-group-10"
            label="Linkedin Profile"
            label-for="input-9"
          >
            <b-form-input
              id="input-9"
              v-model="form.linkedin"
              placeholder="Enter Linkedin Profile"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-11">
            <b-form-checkbox-group
              v-model="form.checked"
              id="checkboxes-10"
              required
            >
              <b-form-checkbox v-model="form.privacy" required
                >I Agree with the
                <a
                  target="_blank"
                  href="https://www.iubenda.com/privacy-policy/18888635"
                  >Privacy Policy</a
                ></b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>

          <b-button class="mt-5" block type="submit" variant="primary"
            ><b-spinner
              v-if="submitted"
              small
              type="grow"
              class="mr-2"
            ></b-spinner
            >Access Reports</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2.js";

require("firebase/auth");
require("firebase/firestore");

export default {
  name: "CompleteSignup",
  components: { Multiselect },
  data() {
    return {
      submitted: false,
      form: {
        fistName: null,
        lastName: null,
        jobTitle: null,
        company: null,
        country: null,
        companyType: null,
        foundUs: null,
        linkedin: null,
        interests: null,
        privacy: false,
      },
      companyTypeOptions: [
        { text: "Select One", value: null },
        {
          text: "Government Agency / Ecosystem Builder ",
          value: "ecosystem_partner",
        },
        {
          text: "Corporate",
          value: "corporate",
        },
        {
          text: "Startup",
          value: "startup",
        },
        {
          text: "Investor",
          value: "investor",
        },
        {
          text: "University / Research Institute",
          value: "research",
        },
        {
          text: "Consultancy",
          value: "consultancy",
        },
      ],
      foundUsOption: [
        { text: "Select One", value: null },
        "Mind the Bridge Employee",
        "Newsletter",
        "Partner",
        "Search Engine",
        "Social Media",
        "Webinar",
        "Word of Mouth",
      ],
      interestsOptions: [
        "Aeronautics & Space",
        "Agriculture",
        "Automotive",
        "Biotech, Life Sciences & Pharma",
        "Business Services & Consulting",
        "Chemicals",
        "Construction",
        "Defense",
        "Education Services",
        "Electronics",
        "Energy & Power",
        "Environmental service",
        "Fashion & Luxury",
        "Finance & Banking",
        "Food & Beverage",
        "Healthcare",
        "Insurance",
        "IT & Software",
        "Logistics & Shipping",
        "Machinery",
        "Manufacturing",
        "Media & Entertainment",
        "NGO",
        "Oil & Gas",
        "Other",
        "Real Estate",
        "Retail",
        "Telecommunications",
        "Tobacco",
        "Transportation",
        "Trading",
        "Travel & Hospitality",
      ],
      countryOptions: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua & Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas, The",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Is.",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burma",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Rep.",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo, Dem. Rep.",
        "Congo, Repub. of the",
        "Cook Islands",
        "Costa Rica",
        "Cote Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "Gabon",
        "Gambia, The",
        "Gaza Strip",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Fed. St.",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "N. Mariana Islands",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Helena",
        "Saint Kitts & Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome & Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "St Pierre & Miquelon",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks & Caicos Is",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Virgin Islands",
        "Wallis and Futuna",
        "West Bank",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
    };
  },
  components: { Multiselect },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.submitted = true;
      firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.email)
        .update({
          company: this.form.company,
          companyType: this.form.companyType,
          firstName: this.form.firstName,
          foundUs: this.form.foundUs,
          hasCompletedSignup: true,
          interests: this.form.interests,
          jobTitle: this.form.jobTitle,
          lastName: this.form.lastName,
          linkedin: this.form.linkedin,
          privacy: this.form.privacy,
          country: this.form.country,
          downloaded: [],
        })
        .then(() => {
          this.$store.dispatch("setUserInfo").then(() => {
            // this.recordUserAirtable()
            this.$router.push({ name: "Reports" });
          });
        })
        .catch((error) => {
          console.error("Error writing user document: ", error);
        });
    },
    recordUserAirtable() {
      axios
        .get(
          "https://us-central1-mtb-research.cloudfunctions.net/recordUserAirtable",
          {
            params: {
              name: this.form.firstName + " " + this.form.lastName,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              email: firebase.auth().currentUser.email,
              company: this.form.company,
              title: this.form.jobTitle,
              linkedin: this.form.linkedin ? this.form.linkedin : null,
              interests: this.form.interests,
              source: this.form.foundUs,
              country: this.form.country,
            },
          }
        )
        .then((res) => {
          // console.log("USER ADDED ON AIRTABLE")
          this.createLead(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createLead(IdAT) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            Authorization: "Bearer " + token,
          };
          axios
            .get(
              "https://us-central1-mtb-research.cloudfunctions.net/createLeadSF",
              {
                params: {
                  firstName: this.form.firstName,
                  lastName: this.form.lastName,
                  email: firebase.auth().currentUser.email,
                  company: this.form.company,
                  title: this.form.jobTitle,
                  source: this.form.foundUs,
                  type: this.form.companyType,
                  // sectors:this.form.interests,
                  airtableId: IdAT,
                  country: this.form.country,
                },
                headers: headers,
              }
            )
            .then(() => {
              this.$router.push({ name: "Reports" });
            })
            .catch((e) => {
              console.log(e);
            });
        });
    },
  },
  created() {
    if (this.$store.state.userInfo) {
      if (this.$store.state.userInfo.hasCompletedSignup) {
        this.$router.push({ name: "Reports" });
      }
    } else {
      this.$store.dispatch("setUserInfo").then(() => {
        if (this.$store.state.userInfo.hasCompletedSignup) {
          this.$router.push({ name: "Reports" });
        }
      });
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
